import { useContext } from "react";
import classes from "./TabButton.module.css";
import BadgeDataContext from "../../store/badgeData-context";

const TabButton = (props) => {
  const badgeDataCtx = useContext(BadgeDataContext);

  const onClickHandler = () => {
    badgeDataCtx.loadBadgesData(props.id);
  };

  return (
    <button className={classes.button} onClick={onClickHandler}>
      {props.label}
    </button>
  );
};

export default TabButton;
