import { useState, useEffect, useContext } from 'react';
import Modal from '../UI/Modal';
import { drawCenteredText, drawMultilineText } from './PdfTextWriter';
import { PDFDocument, rgb, grayscale, StandardFonts } from 'pdf-lib';

import BadgeDataContext from '../../store/badgeData-context';
import classes from './CertGenerator.module.css';
import bg from '../../assets/branding/digital_passport-splashscreen-logo.png';
import dpBanner from '../../assets/branding/dp-transcript-banner.png';
import t2cBanner from '../../assets/branding/t2c-transcript-banner.png';
import loriSign from '../../assets/transcripts/loriSign.png';

const TranscriptGenerator = (props) => {
  const badgeDataCtx = useContext(BadgeDataContext);
  const [trxInfo, setTrxInfo] = useState([]);
  useEffect(() => {
    createTranscript();
  }, []);

  const createCourseTranscriptBox = (
    page,
    badgeObj,
    badgeImg,
    titleFont,
    descFont,
    lineSpace
  ) => {
    const { width, height } = page.getSize();

    lineSpace += 10;
    //Course badge image
    page.drawImage(badgeImg, {
      x: 20,
      y: height - lineSpace - 50,
      width: 60,
      height: 60,
    });
    //Course title
    page.drawText(badgeObj.courseTitle, {
      x: 80,
      y: height - lineSpace,
      size: 20,
      font: titleFont.font,
      color: rgb(0, 0, 0),
    });
    //Credit Hours
    page.drawText(`Credit Hours: ${badgeObj.credHrs}`, {
      x: width - 163,
      y: height - lineSpace,
      size: descFont.size,
      font: descFont.font,
      color: descFont.color,
    });
    lineSpace += 15;
    //Comp date
    const complDate = badgeDataCtx.currStudent.earnedBadges.find((obj) => {
      return obj.id === badgeObj.id;
    }).compDate;
    page.drawText(`(CMPL: ${complDate})`, {
      x: 80,
      y: height - lineSpace,
      size: 12,
      font: descFont.font,
      color: descFont.color,
    });
    //course description
    return drawMultilineText(
      badgeObj.courseDesc,
      page,
      descFont,
      lineSpace,
      30,
      60
    );
  };

  const createTranscript = async () => {
    const grdLvlBadges = badgeDataCtx.getBadgeList(props.grdLvl);
    const banner = props.grdLvl === 'T2C' ? t2cBanner : dpBanner;
    const trxBadges = [];
    let studentName = '';
    let creditHours = 0;
    for (let i in badgeDataCtx.currStudent.earnedBadges) {
      const earnedBadge = badgeDataCtx.currStudent.earnedBadges[i];
      const earnedBadgeDetails = grdLvlBadges.find((badge) => {
        return badge.id === earnedBadge.id;
      });
      if (earnedBadgeDetails) {
        trxBadges.push(earnedBadgeDetails);
        creditHours += +earnedBadgeDetails.credHrs;
        if (earnedBadge.recip && earnedBadge.recip !== 'Not Specified') {
          studentName = earnedBadge.recip;
        }
      }
    }

    const pdfDoc = await PDFDocument.create();
    const bgImg = await pdfDoc.embedPng(bg);

    const bannerImg = await pdfDoc.embedPng(banner);

    const arrayBuffer = await fetch(loriSign).then((res) => res.arrayBuffer());

    const loriSignature = await pdfDoc.embedPng(arrayBuffer);

    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();

    const titleText = {
      font: await pdfDoc.embedFont(StandardFonts.HelveticaOblique),
      size: 30,
      color: rgb(0, 0, 0),
    };
    const headerText = {
      font: await pdfDoc.embedFont(StandardFonts.HelveticaBold),
      size: 30,
      color: rgb(1, 1, 1),
    };

    const descText = {
      font: await pdfDoc.embedFont(StandardFonts.Helvetica),
      size: 15,
      color: rgb(0, 0, 0),
    };

    //create background watermark
    page.drawRectangle({
      x: 0,
      y: 0,
      width: width,
      height: height,
      color: grayscale(0.95),
    });

    page.drawImage(bgImg, {
      x: 0,
      y: 0,
      width: width,
      height: height,
    });
    //end create background watermark

    //draw MCDP Header Logo
    page.drawImage(bannerImg, {
      x: 0,
      y: height - 70,
      width: width,
      height: 70,
    });
    //end draw MCDP Header Logo

    //transcript title
    let lineSpace = 60;
    lineSpace = drawCenteredText(
      'Experience Transcript',
      page,
      titleText,
      lineSpace
    );
    //end transcript title

    lineSpace += 20;

    lineSpace += 40;
    //draw transcript summary
    if (studentName !== '') {
      page.drawText(`Student: ${studentName}`, {
        x: 20,
        y: height - lineSpace,
        size: descText.size,
        font: descText.font,
        color: descText.color,
      });
    }

    page.drawText(`Total Credit Hours: ${creditHours}`, {
      x:
        width -
        100 -
        descText.font.widthOfTextAtSize('Credit Hours: 3', descText.size),
      y: height - lineSpace,
      size: descText.size,
      font: descText.font,
      color: descText.color,
    });
    page.drawRectangle({
      x: 20,
      y: height - lineSpace - 10,
      width: width - 80,
      height: 2,
      color: grayscale(0),
    });

    //end draw transcript summary
    lineSpace += 30;
    for (let i in trxBadges) {
      lineSpace += 10;
      const badgeImg = await pdfDoc.embedPng(trxBadges[i].img);
      lineSpace = createCourseTranscriptBox(
        page,
        trxBadges[i],
        badgeImg,
        headerText,
        descText,
        lineSpace
      );
    }
    lineSpace += 50;
    page.drawImage(loriSignature, {
      x: width / 2 - 75,
      y: height - lineSpace,
      width: 150,
      height: 50,
    });

    page.drawRectangle({
      x: width / 2 - 100,
      y: height - lineSpace - 5,
      width: 200,
      height: 2,
      color: grayscale(0),
    });

    drawCenteredText('Lori Schaller', page, descText, lineSpace);
    lineSpace += 20;
    drawCenteredText('Executive Director', page, descText, lineSpace);
    lineSpace += 20;
    drawCenteredText(
      'Digital Passport | Teens 2 Careers',
      page,
      descText,
      lineSpace
    );
    lineSpace += 20;
    drawCenteredText(
      'A program of United Way of Mercer County',
      page,
      descText,
      lineSpace
    );

    const pdfBytes = await pdfDoc.save();
    const docUrl = URL.createObjectURL(
      new Blob([pdfBytes], { type: 'application/pdf' })
    );

    setTrxInfo(docUrl);
  };

  return (
    <Modal onClose={props.onClose}>
      <iframe
        className={classes.frame}
        title="transcriptPdf"
        width="600"
        height="900"
        src={trxInfo}
      />
    </Modal>
  );
};

export default TranscriptGenerator;
