import React, { useContext, useState } from "react";
import BadgeDataContext from "../../store/badgeData-context";
import classes from "./CodeInput.module.css";
const CodeInput = (props) => {
  const badgeDataCtx = useContext(BadgeDataContext);
  const [enteredCode, setEnteredCode] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const codeIsInvalid = enteredCode.length !== 6 && isTouched;

  const isReadyToSubmit = enteredCode.length === 6;

  const codeChangeHandler = (event) => {
    setEnteredCode(event.target.value);
  };
  const codeBlurHandler = () => {
    setIsTouched(true);
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    badgeDataCtx.fetchData(enteredCode);
    // //delete this. this is temporary function to remove special chars
    // //special char replacement '$' --> D, '#' --> 'H', '+' --> 'P', '?' --> 'P'
    // let repStr = enteredCode
    //   .replace("$", "D")
    //   .replace("#", "H")
    //   .replace("+", "P")
    //   .replace("?", "Q").toUpperCase();
    // console.log(repStr);
    // //end temporary fxn section

    // const response = await fetch(
    //   `https://react-http-tester-4c675-default-rtdb.firebaseio.com/students/${repStr}.json`
    // );

    // if (!response.ok) {
    //   throw new Error("Something went wrong!");
    // }

    // const responseData = await response.json();

    // badgeDataCtx.loadCurrStudent(responseData);
    // setIsLoading(false);

    /*
    const encodedCode = encodeURIComponent(enteredCode.toUpperCase());

    const response = await fetch(
      `https://3ifzf06olk.execute-api.us-east-1.amazonaws.com/dc-staging/badges?room_id=${encodedCode}`
    );

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    let transformedData = {"earnedBadges" : {}, "id" : "" };

    const responseData = await response.json();

    transformedData["id"] = responseData[0]["room_id"];

    for(let objNum in responseData){
      let awarded_at = responseData[objNum]["awarded_at"];
      transformedData["earnedBadges"][responseData[objNum]["badge_id"]] = awarded_at;
    }

    badgeDataCtx.loadCurrStudent(transformedData);
    */
    setIsLoading(false);
  };

  const codeInputClasses = codeIsInvalid ? classes.invalid : classes.input;

  return (
    <React.Fragment>
      <input
        className={codeInputClasses}
        onChange={codeChangeHandler}
        onBlur={codeBlurHandler}
        value={isLoading ? "loading" : enteredCode}
      />
      <button disabled={!isReadyToSubmit} onClick={onSubmitHandler}>
        Submit Code
      </button>
    </React.Fragment>
  );
};

export default CodeInput;
