import React, { useState } from "react";
import BadgeDetail from "./components/badges/BadgeDetail";
import Badges from "./components/badges/Badges";
import MainHeader from "./components/Layout/MainHeader";
import TabBanner from "./components/Layout/TabBanner";
import BadgeDataProvider from "./store/BadgeDataProvider";
import styles from "./App.css";

function App() {
  //get parameter from url if exists 
  //test
  const queryParameters = new URLSearchParams(window.location.search);
  const invitationCode = queryParameters.get("invitationCode");

  const [showDetail, setShowDetail] = useState(false);

  const showBadgeDetailHandler = () => {
    setShowDetail(true);
  };

  const hideBadgeDetailHandler = () => {
    setShowDetail(false);
  };

  return (
    <BadgeDataProvider room_id={invitationCode}>
      {showDetail && <BadgeDetail onClose={hideBadgeDetailHandler} />}
      <MainHeader />
      <TabBanner />
      <Badges onBadgeClicked={showBadgeDetailHandler} />
      <div >
        <p className={styles.policies}>
          <a href="https://ohanalinkpartners.tech/mc-dp-privacy">
            Privacy Policy
          </a>
          <a href="https://ohanalinkpartners.tech/mc-dp-terms">Terms Of Use</a>
        </p>
      </div>
    </BadgeDataProvider>
  );
}

export default App;
