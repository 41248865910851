import { useContext } from "react";
import BadgeDataContext from "../../store/badgeData-context";

const Badge = (props) => {

  const badgeDataCtx = useContext(BadgeDataContext);

  const onClickHandler = () => {
    badgeDataCtx.loadCurrBadge(props.id);
    props.onBadgeClicked();
  }

  return (
    <img
      src={props.img}
      className={props.classes}
      onClick={onClickHandler}
    />
  );
};
export default Badge;
