import { useContext, useState } from "react";
import BadgeDataContext from "../../store/badgeData-context";
import CertGenerator from "../pdfGenerators/CertGenerator";
import TranscriptGenerator from "../pdfGenerators/TranscriptGenerator";
import Modal from "../UI/Modal";
import classes from "./BadgeDetail.module.css";

const BadgeDetail = (props) => {
  const [showCert, setShowCert] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);
  const badgeDataCtx = useContext(BadgeDataContext);
  const courseInfo = badgeDataCtx.currStudent.earnedBadges.find(
    (e) => e.id === badgeDataCtx.currBadge.id
  );

  const hideCertHandler = () => {
    setShowCert(false);
  };

  const hideTranscriptHandler = () => {
    setShowTranscript(false);
  };

  const compDate = courseInfo ? courseInfo.compDate : "TBD";

  return (
    <Modal onClose={props.onClose}>
      {showCert && <CertGenerator onClose={hideCertHandler} />}
      {showTranscript && (
        <TranscriptGenerator onClose={hideTranscriptHandler} grdLvl="T2C" />
      )}
      <img src={badgeDataCtx.currBadge.img} className={classes.img} />
      <p>{badgeDataCtx.currBadge.courseTitle}</p>
      <p>Completion Date: {compDate}</p>
      <p>{badgeDataCtx.currBadge.courseDesc}</p>
      <button onClick={props.onClose}>Close</button>
      {badgeDataCtx.currBadgeHasCert && (
        <button onClick={() => setShowCert(true)}>Certificate</button>
      )}
      {badgeDataCtx.isTranscript && (
        <button onClick={() => setShowTranscript(true)}>Transcript</button>
      )}
    </Modal>
  );
};

export default BadgeDetail;
