import CodeInput from "../UI/CodeInput";
import classes from "./Navigation.module.css";

const Navigation = () => {
    return (
        <nav className={classes.nav}>
            <CodeInput classes={classes.input}/>
        </nav>
    )
}

export default Navigation;