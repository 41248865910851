import { useState, useEffect } from 'react';

import b1 from '../assets/badges/G4-5/45c-coding_events.png';
import b2 from '../assets/badges/G4-5/45c-cool_coding.png';
import b3 from '../assets/badges/G4-5/45c-groovy_loops.png';
import b4 from '../assets/badges/G4-5/45c-spec_seq1.png';
import b5 from '../assets/badges/G4-5/45dls-creative_media.png';
import b6 from '../assets/badges/G4-5/45dls-device_nav.png';
import b7 from '../assets/badges/G4-5/45dls-dig_citizen.png';
import b8 from '../assets/badges/G4-5/45dls-dig_privacy.png';
import b9 from '../assets/badges/G4-5/45dls-res_sources.png';
import b10 from '../assets/badges/G4-5/45dls-web_design.png';
import b11 from '../assets/badges/G4-5/45dls-web_nav.png';
import b12 from '../assets/badges/G4-5/45r-robot_man.png';
import b13 from '../assets/badges/G4-5/45r-rock_robot_challenge.png';
import b14 from '../assets/badges/G4-5/45t-design_discovery.png';
import b14_5 from '../assets/badges/G4-5/45STEM_project.png';
import b15 from '../assets/badges/G6-7/67c-coding_events.png';
import b16 from '../assets/badges/G6-7/67c-creative_coding_fund2.png';
import b17 from '../assets/badges/G6-7/67c-lawless_loops.png';
import b18 from '../assets/badges/G6-7/67c-superb_seq2.png';
import b19 from '../assets/badges/G6-7/67dls-creative_media.png';
import b20 from '../assets/badges/G6-7/67dls-digital_footprint.png';
import b21 from '../assets/badges/G6-7/67dls-digital_resp.png';
import b22 from '../assets/badges/G6-7/67dls-os_nav.png';
import b23 from '../assets/badges/G6-7/67dls-research_app.png';
import b24 from '../assets/badges/G6-7/67dls-web_dev.png';
import b25 from '../assets/badges/G6-7/67dls-web_manipulation.png';
import b26 from '../assets/badges/G6-7/67r-rebel_robot_challenge.png';
import b27 from '../assets/badges/G6-7/67r-robot_manufacturing.png';
import b28 from '../assets/badges/G6-7/67t-design_invent_solution.png';
import b29 from '../assets/badges/G8-9/89c-coding_events.png';
import b30 from '../assets/badges/G8-9/89c-complex_coding_fund3.png';
import b31 from '../assets/badges/G8-9/89c-levelup_onloops.png';
import b32 from '../assets/badges/G8-9/89c-soph_sequencing2.png';
import b33 from '../assets/badges/G8-9/89dls-creative_media.png';
import b34 from '../assets/badges/G8-9/89dls-cybersecurity.png';
import b35 from '../assets/badges/G8-9/89dls-digital_app.png';
import b36 from '../assets/badges/G8-9/89dls-digital_copyright.png';
import b37 from '../assets/badges/G8-9/89dls-digital_id.png';
import b38 from '../assets/badges/G8-9/89dls-digital_media.png';
import b39 from '../assets/badges/G8-9/89dls-website_creation.png';
import b40 from '../assets/badges/G8-9/89r-robot_manufacturing.png';
import b41 from '../assets/badges/G8-9/89r-rogue_robot_challenge.png';
import b42 from '../assets/badges/G8-9/89t-digital_entrepreneurship.png';
import b43 from '../assets/badges/T2C/t2c-teamwork.png';
import b44 from '../assets/badges/T2C/t2c-careers.png';
import b45 from '../assets/badges/T2C/t2c-time_mgmt.png';
import b46 from '../assets/badges/T2C/t2c-intro_entrepreneur.png';
import b47 from '../assets/badges/T2C/t2c-entrepreneur_bus_basics.png';
import b48 from '../assets/badges/T2C/t2c-entrepreneurship.png';

import b49 from '../assets/badges/G6-7/67e-entrepreneur-novice.png';
import b50 from '../assets/badges/G6-7/67e-entrepreneur-creativity.png';
import b51 from '../assets/badges/G6-7/67e-entrepreneur-explorer.png';

import b52 from '../assets/badges/G8-9/89e-entrepreneur-novice.png';
import b53 from '../assets/badges/G8-9/89e-entrepreneur-creativity.png';
import b54 from '../assets/badges/G8-9/89e-entrepreneur-explorer.png';

import b55 from '../assets/badges/T2C/t2c-disease_detective.png';
import b56 from '../assets/badges/T2C/t2c-steam_baughman.png';
import b57 from '../assets/badges/T2C/t2c-csi.png';
import b58 from '../assets/badges/T2C/t2c-leadership.png';
import b59 from '../assets/badges/T2C/t2c-media_comms-thiel.png';
import b60 from '../assets/badges/T2C/t2c-events.png';
import b61 from '../assets/badges/T2C/t2c-virtual_game.png';
import b62 from '../assets/badges/T2C/t2c-culinary.png';
import b63 from '../assets/badges/T2C/t2c-food_science.png';
import b64 from '../assets/badges/T2C/t2c-performing_arts.png';
import b65 from '../assets/badges/T2C/t2c-great_outdoors.png';

import transcriptsImg2 from '../assets/tabs/dp-mytranscripts.png';
import transcriptsImg from '../assets/transcripts/t2c-transcript-icon.png';

import BadgeDataContext from './badgeData-context';

const badges45Data = [
  {
    id: 'b1',
    img: b1,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Coding Events',
    courseDesc:
      'Student developed a code that successfully completes the Ozoblocky Activities.',
  },
  {
    id: 'b2',
    img: b2,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Cool Coding Fundamentals',
    courseDesc: 'This is a description for Cool Coding Fundamentals',
  },
  {
    id: 'b3',
    img: b3,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Groovy Loops',
    courseDesc:
      'Students successfully achieved the following coding activities using  Ozoblockly Activities: Linear Sequence, Hack the Square, and Repeat a Sequence',
  },
  {
    id: 'b4',
    img: b4,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Spectacular Sequencing I',
    courseDesc:
      'Student successfully completes block coding for the Ozobot robot including changing colors, moving forward, and repeating commands',
  },
  {
    id: 'b5',
    img: b5,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Creative Media',
    courseDesc:
      'Student successfully created of a GIF that displays an emotion or promotion of a product.',
  },
  {
    id: 'b6',
    img: b6,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Device Navigation',
    courseDesc:
      'Student created a flyer using word processing and/or presentation tools.',
  },
  {
    id: 'b7',
    img: b7,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Digital Citizenship',
    courseDesc:
      'Student successfully completed the Ring of Responsibility Activity to encourage appropriate online decisions.',
  },
  {
    id: 'b8',
    img: b8,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Digital Privacy',
    courseDesc:
      'Student successfully completed Digital Privacy lesson activity and Password Protect activity to increase their understanding of the importance of digital privacy.',
  },
  {
    id: 'b9',
    img: b9,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Research Sources',
    courseDesc:
      'Student identified credible sources such as advertisements or commercials with 80% accuracy using effective Google search questions building internet research skills.',
  },
  {
    id: 'b10',
    img: b10,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Website Design',
    courseDesc:
      'Student successfully completed the Internet Scavenger Hunt Activity using search engines to locate information',
  },
  {
    id: 'b11',
    img: b11,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Website Navigation',
    courseDesc:
      'Students will be Introduced to various search engines Work individually or in pairs to complete the Internet Scavenger Hunt Activity.',
  },
  {
    id: 'b12',
    img: b12,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Robot Manufacturing',
    courseDesc:
      'Part 1- Successful completion of the littleBits Basic Circuit Activity. Part 2- Successful completion of the littleBits Advanced Circuit Activity.',
  },
  {
    id: 'b13',
    img: b13,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Rocking Robot Challenge',
    courseDesc: 'Student successfully assembles the ClicBot Bic & Bac Robots',
  },
  {
    id: 'b14',
    img: b14,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'Design & Discovery',
    courseDesc: 'Design & Discovery',
  },
  {
    id: 'b14_5',
    img: b14_5,
    gradeLvl: 'G45',
    credHrs: '1',
    courseTitle: 'STEM Project 2023',
    courseDesc:
      'Students completed a STEM project applying Digital Literacy and Coding.',
  },
];
const badges67Data = [
  {
    id: 'b15',
    img: b15,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Coding Events II',
    courseDesc:
      'Students will use a Block program for a rover that recognizes an obstacle and reacts to it by moving away and changes color outputs when it does.-Students will complete the "Going Further, Rover: Driving and Avoiding Obstacles" exercises to turn the headlights red when the rover is moving away from an obstacle and green when the rover is moving forward.',
  },
  {
    id: 'b16',
    img: b16,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Creative Coding Fundamentals II',
    courseDesc:
      'Students will use a Block program to control and change an output for the Hummingbird.-Students will complete "USING THE OUTPUTS"  exercises to control and change an output on the Hummingbird.',
  },
  {
    id: 'b17',
    img: b17,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Lawless Loops',
    courseDesc:
      'Students will use a Block Program that loops 2 separate outputs in different ways.-Students will complete the Challenge to build a robot that uses 2 slow moving servos.-Students will build a program that uses 2 slow moving servos.',
  },
  {
    id: 'b18',
    img: b18,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Superb Sequencing II',
    courseDesc:
      'Students will use a Block program to control and change an input for the Hummingbird.-Students will complete “USING THE INPUTS”  exercises to control and change an input on the Hummingbird.',
  },
  {
    id: 'b19',
    img: b19,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Creative Media II',
    courseDesc:
      'Students created an Infographic, visual image, on a chosen topic.',
  },
  {
    id: 'b20',
    img: b20,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Digital Footprint',
    courseDesc:
      'Student completed project activity reflecting understanding of Digital Footprint and online activity.',
  },
  {
    id: 'b21',
    img: b21,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Digital Responsibility',
    courseDesc:
      'Student participated and finished activities that demonstrate understanding of digital responsibility, using technology in a constructive manner.',
  },
  {
    id: 'b22',
    img: b22,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Operating Systems Navigation',
    courseDesc:
      'Student completed a Google Slide Show which reflects ability to employ digital tools to create work.',
  },
  {
    id: 'b23',
    img: b23,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Research Application',
    courseDesc:
      'Student project utilizes the web to research a topic and cite sources of information.',
  },
  {
    id: 'b24',
    img: b24,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Website Development',
    courseDesc:
      'Student developed a new website with content using Google Sites.',
  },
  {
    id: 'b25',
    img: b25,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Website Manipulation',
    courseDesc: 'Student project reflects website edit and refinement skills.',
  },
  {
    id: 'b26',
    img: b26,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Rebel Robot Challenge',
    courseDesc:
      'Students will create a new Hummingbird program and then build the body of the robot to combine the two.-Incorporate their Hummingbird parts to make it move and respond.',
  },
  {
    id: 'b27',
    img: b27,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Robot Manufacturing II',
    courseDesc:
      'Students will construct a circuit that accepts input and delivers an output appropriate for the input.-Students will use the kit to build an original circuit that accepts stimulus (input) and delivers a response (output) appropriate for the input.',
  },
  {
    id: 'b28',
    img: b28,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Design Invention Solution',
    courseDesc: 'Design  Invention Solution',
  },
  {
    id: 'b49',
    img: b49,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Entrepreneur Novice',
    courseDesc:
      'Students create an Elevator Pitch of developed entrepreneur concept. Curriculum Activity: Completion of Elevator Pitch of Entrepreneur Business and Presentation',
  },
  {
    id: 'b50',
    img: b50,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Entrepreneur Creativity',
    courseDesc:
      'Students apply entrepreneur traits to idea. Curriculum Activity: -Development of Idea, Hook, Business Name, Logo, Tagline.',
  },
  {
    id: 'b51',
    img: b51,
    gradeLvl: 'G67',
    credHrs: '1',
    courseTitle: 'Entrepreneur Explorer',
    courseDesc:
      'Students demonstrate understanding of entrepreneurship. Curriculum Activity: -Participation in either Personality Quiz/Entrepreneur Quiz/Teen Entrepreneur Business Pitches -Completion of Mind Map ',
  },
];
const badges89Data = [
  {
    id: 'b29',
    img: b29,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Coding Events III',
    courseDesc:
      'Students have built a block program in which a flower blooms when an accelerometer is tilted.',
  },
  {
    id: 'b30',
    img: b30,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Complex Coding Fundamentals III',
    courseDesc:
      'Students have designed a block program that controls and changes an output for the Hummingbird robot.',
  },
  {
    id: 'b31',
    img: b31,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Level-up on Loops',
    courseDesc:
      'Students have built a program that uses 2 slow moving servos to track and move along a line as a foundation for a Robotics badge to build the robot that will use this program.',
  },
  {
    id: 'b32',
    img: b32,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Sophisticated Sequencing II',
    courseDesc:
      'Students have demonstrated sequencing through creating a block program that uses an input to control an output for the Hummingbird robot.',
  },
  {
    id: 'b33',
    img: b33,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Creative Media III',
    courseDesc:
      'Students have developed a Google site page that explains the difference between Addictive Design vs. Humane Design.',
  },
  {
    id: 'b34',
    img: b34,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Cybersecurity',
    courseDesc:
      'Students complete a project outlining cybersecurity  to identify and avoid online scams by analyzing a real-life situation in a group.',
  },
  {
    id: 'b35',
    img: b35,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Digital Applications',
    courseDesc:
      'Students have created a PSA (Public Service Announcement) video about the importance of Digital Identity.',
  },
  {
    id: 'b36',
    img: b36,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Digital Copyright',
    courseDesc:
      'Students have created a research project and Google web page, while being sure to cite sources.',
  },
  {
    id: 'b37',
    img: b37,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Digital Identity',
    courseDesc:
      '-Students will define and analyze credible sources by identifying sources as ads, educational, or commercial with 80% accuracy.-Review credible sources, ads, educational and commercial.-Develop effective google search questions.-Identify sources as credible, advertisement, educational or commercial-Students will compile a minimum of three sets of resources.',
  },
  {
    id: 'b38',
    img: b38,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Digital Media',
    courseDesc:
      'Students developed an idea for a website delivering information about a place or product, and including, text, pictures, and video, demonstrating an understanding of key components of digital media.',
  },
  {
    id: 'b39',
    img: b39,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Website Creation',
    courseDesc:
      'Students have created a website using an original concept and digital media design.',
  },
  {
    id: 'b40',
    img: b40,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Robot Manufacturing III',
    courseDesc:
      'Students built a robot that will use code written during the coding badge to follow a line.',
  },
  {
    id: 'b41',
    img: b41,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Rogue Robot Challenge',
    courseDesc:
      'Students build a robotic flower that they can make bloom by moving when an accelerometer is tilted.',
  },
  {
    id: 'b42',
    img: b42,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Design Entrepreneurship',
    courseDesc: 'This is a description for Design & Discovery',
  },
  {
    id: 'b52',
    img: b52,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Entrepreneur Novice',
    courseDesc:
      'Students create an Elevator Pitch of developed entrepreneur concept. Curriculum Activity: Completion of Elevator Pitch of Entrepreneur Business and Presentation',
  },
  {
    id: 'b53',
    img: b53,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Entrepreneur Creativity',
    courseDesc:
      'Students apply entrepreneur traits to idea. Curriculum Activity: -Development of Idea, Hook, Business Name, Logo, Tagline.',
  },
  {
    id: 'b54',
    img: b54,
    gradeLvl: 'G89',
    credHrs: '1',
    courseTitle: 'Entrepreneur Explorer',
    courseDesc:
      'Students demonstrate understanding of entrepreneurship. Curriculum Activity: -Participation in either Personality Quiz/Entrepreneur Quiz/Teen Entrepreneur Business Pitches -Completion of Mind Map ',
  },
];
const badgesT2CData = [
  {
    id: 'b43',
    img: b43,
    gradeLvl: 'T2C',
    credHrs: '1',
    courseTitle: 'Team Work',
    courseDesc:
      'Students worked effectively in teams to complete career focused projects.',
  },
  {
    id: 'b44',
    img: b44,
    gradeLvl: 'T2C',
    credHrs: '1',
    courseTitle: 'STEM Careers',
    courseDesc:
      'Students will have completed hands-on activities aligned with a focus career increasing their understanding of relative skills and career paths.',
  },
  {
    id: 'b45',
    img: b45,
    gradeLvl: 'T2C',
    credHrs: '1',
    courseTitle: 'Time Management',
    courseDesc:
      'Students were able to effectively manage planning and implementing tasks on scheduled for career based activities.',
  },
  {
    id: 'b46',
    img: b46,
    gradeLvl: 'T2C',
    credHrs: '1',
    courseTitle: 'Intro to Entrepreneur',
    courseDesc:
      'Students demonstrate understanding of entrepreneurship. Curriculum Activity: -Participation in Introduction to Entrepreneurship Activities. -Completion of Hook.',
  },
  {
    id: 'b47',
    img: b47,
    gradeLvl: 'T2C',
    credHrs: '1',
    courseTitle: 'Entrepreneurial Business Basics',
    courseDesc:
      'Students reflect understanding of business in developing model. Curriculum Activity: Completion of Business Model Canvas',
  },
  {
    id: 'b48',
    img: b48,
    gradeLvl: 'T2C',
    credHrs: '1',
    courseTitle: 'Entrepreneurship',
    courseDesc:
      'Students have completed a business plan to pitch. Curriculum Activity: Complete Entrepreneur Business Plan, Present Elevator Pitch of Business Plan ',
  },
  {
    id: 'b55',
    img: b55,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'Disease Detectives',
    courseDesc:
      'Offering hands-on activities focused on healthcare and health sciences.',
  },
  {
    id: 'b56',
    img: b56,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'STEAM at Baughman Center',
    courseDesc:
      'Exploration in STEAM projects to build 21st century skills for future careers.',
  },
  {
    id: 'b57',
    img: b57,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'CSI Forensic Investigations',
    courseDesc:
      'Providing interactive projects to introduce science inquiry and healthcare career fields.',
  },
  {
    id: 'b58',
    img: b58,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'T2C Leadership',
    courseDesc:
      'Building leadership skills and providing civic service opportunities.',
  },
  {
    id: 'b59',
    img: b59,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'Media and Communications at Thiel College',
    courseDesc:
      'Providing hands-on experiences in state-of-the-art studio for communication and media careers.',
  },
  {
    id: 'b60',
    img: b60,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'Event Planning & Management',
    courseDesc:
      'Developing project management skills and career awareness through designing and implementing an event.',
  },
  {
    id: 'b61',
    img: b61,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'Virtual Game Design',
    courseDesc:
      'Building understanding and proficiency coding through application of the Godot 4 Engine.',
  },
  {
    id: 'b62',
    img: b62,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'Culinary Studio',
    courseDesc:
      'The Science of the Dough- Introduction to culinary arts through hands-on culinary projects at the Mercer County Career Center.',
  },
  {
    id: 'b63',
    img: b63,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'Food science & Forensics',
    courseDesc:
      'Investigating culinary and science careers through STEM projects.',
  },
  {
    id: 'b64',
    img: b64,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'T2C Performing Arts Studio ',
    courseDesc:
      'Exploring performing arts careers through 21st century skills.',
  },
  {
    id: 'b65',
    img: b65,
    gradLvl: 'T2C',
    credHrs: '3',
    courseTitle: 'The Great Outdoor',
    courseDesc:
      'Provides environmental education and career exploration through hands on projects.',
  },
];
const transcripts = [
  {
    id: 'T2C',
    img: transcriptsImg,
    gradeLvl: 'transcripts',
    courseTitle: 'Teens-2-Careers',
    courseDesc: 'Teens-2-Careers Transcripts',
  },
];

const defaultBadgeDataState = {
  currStudent: { id: '', earnedBadges: [] },
  currBadge: '',
  badgesData: badges45Data,
};

const BadgeDataProvider = (props) => {
  const [badgeDataState, setBadgeDataState] = useState(defaultBadgeDataState);
  const [viewingMyBadges, setViewingMyBadges] = useState(false);
  const [currBadgeHasCert, setCurrBadgeHasCert] = useState(false);
  const [isTranscript, setIsTranscript] = useState(false);

  const fecthDataFromDynamoDB = async (roomCode) => {
    const encodedCode = encodeURIComponent(roomCode.toUpperCase());
    //console.log("encodedCode: " + encodedCode);
    const response = await fetch(
      //`https://3ifzf06olk.execute-api.us-east-1.amazonaws.com/dc-staging/badges?room_id=${encodedCode}`
      `https://b69ffct766.execute-api.us-east-1.amazonaws.com/green/badges?room_id=${encodedCode}`
    );

    if (!response.ok) {
      throw new Error('Something went wrong!');
    }

    let transformedData = { earnedBadges: {}, id: '' };

    const responseJson = await response.json();
    //add ability to grab errors from the server here
    const responseData = responseJson.body;

    if (responseData.length === 0) {
      alert('No badges found for: ' + roomCode + '\nPlease check your code.');
      return;
    }

    transformedData['id'] = responseData[0]['room_id'];

    for (let objNum in responseData) {
      let awarded_at = responseData[objNum]['awarded_at'];
      let recip = responseData[objNum]['recipient'];
      let awarded_by = responseData[objNum]['awarded_by'];
      transformedData['earnedBadges'][responseData[objNum]['badge_id']] = {
        recipient: recip,
        awarded_by: awarded_by,
        awarded_at: awarded_at,
      };
    }

    loadCurrStudentHandler(transformedData);
  };

  useEffect(() => {
    if (props.room_id && /^([A-Za-z0-9]{5}[?$#+]{1})$/.test(props.room_id)) {
      fecthDataFromDynamoDB(props.room_id);
    }
  }, []);

  const loadCurrBadgesData = (grdLvl) => {
    let updatedBadgesData = [];
    setViewingMyBadges(grdLvl === 'MyBadges');
    if (grdLvl === 'G45') {
      updatedBadgesData = badges45Data;
    } else if (grdLvl === 'G67') {
      updatedBadgesData = badges67Data;
    } else if (grdLvl === 'G89') {
      updatedBadgesData = badges89Data;
    } else if (grdLvl === 'T2C') {
      updatedBadgesData = badgesT2CData;
    } else if (grdLvl === 'MyTranscripts') {
      updatedBadgesData = transcripts;
    } else {
      updatedBadgesData = [].concat(
        badges45Data,
        badges67Data,
        badges89Data,
        badgesT2CData
      );
    }
    const updatedState = {
      currStudent: badgeDataState.currStudent,
      currBadge: badgeDataState.currBadge,
      badgesData: updatedBadgesData,
    };

    setBadgeDataState(updatedState);
  };

  const loadCurrStudentHandler = (student) => {
    if (student) {
      const newEarnedBadges = [];

      Object.keys(student.earnedBadges).map((key) => {
        newEarnedBadges.push({
          id: key,
          recip: student.earnedBadges[key].recipient,
          awarded_by: student.earnedBadges[key].awarded_by,
          compDate: student.earnedBadges[key].awarded_at,
        });
      });

      const currStudent = {
        id: student.id,
        earnedBadges: newEarnedBadges,
      };

      const updatedState = {
        currStudent: currStudent,
        currBadge: badgeDataState.currBadge,
        badgesData: badgeDataState.badgesData,
      };

      setBadgeDataState(updatedState);
    } else {
      console.log('Invalid student code!!!');
      alert('Invalid student code.');
    }
  };

  const loadCurrBadgeHandler = (id) => {
    const newCurrBadge = badgeDataState.badgesData.find((e) => e.id === id);

    const award = badgeDataState.currStudent.earnedBadges.find(
      (e) => e.id === id
    );
    setCurrBadgeHasCert(!!award && !!award.recip);

    //fix this
    if (newCurrBadge.gradeLvl === 'transcripts') {
      setIsTranscript(true);
    } else {
      setIsTranscript(false);
    }

    const updatedState = {
      currStudent: badgeDataState.currStudent,
      currBadge: newCurrBadge,
      badgesData: badgeDataState.badgesData,
    };
    setBadgeDataState(updatedState);
  };

  const getBadgeList = (grdLvl) => {
    if (grdLvl === 'G45') {
      return badges45Data;
    } else if (grdLvl === 'G67') {
      return badges67Data;
    } else if (grdLvl === 'G89') {
      return badges89Data;
    } else if (grdLvl === 'T2C') {
      return badgesT2CData;
    }
  };

  const badgeDataContext = {
    currStudent: badgeDataState.currStudent,
    currBadge: badgeDataState.currBadge,
    badgesData: badgeDataState.badgesData,
    viewingMyBadges: viewingMyBadges,
    currBadgeHasCert: currBadgeHasCert,
    isTranscript: isTranscript,
    getBadgeList: getBadgeList,
    loadCurrStudent: loadCurrStudentHandler,
    loadCurrBadge: loadCurrBadgeHandler,
    loadBadgesData: loadCurrBadgesData,
    fetchData: fecthDataFromDynamoDB,
  };

  return (
    <BadgeDataContext.Provider value={badgeDataContext}>
      {props.children}
    </BadgeDataContext.Provider>
  );
};

export default BadgeDataProvider;
