import React from "react";

const BadgeDataContext = React.createContext({
  currStudent: { id: "", earnedBadges: [] },
  currBadge: "",
  badgesData: [],
  viewingMyBadges: false,
  currBadgeHasCert: false,
  isTranscript: false,
  getBadgeList: (grdLvl) => {},
  loadCurrStudent: (code) => {},
  loadCurrBadge: (id) => {},
  loadBadgesData: (grdLvl) => {},
});

export default BadgeDataContext;
