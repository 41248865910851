import classes from "./Badge.module.css";
import Badge from "./Badge";
import { useContext, useEffect } from "react";
import BadgeDataContext from "../../store/badgeData-context";

const Badges = (props) => {
  const badgeDataCtx = useContext(BadgeDataContext);

  const badgeJSXElements = [];

  for (const key in badgeDataCtx.badgesData) {
    const currBadge = badgeDataCtx.currStudent.earnedBadges.find(
      (e) => e.id === badgeDataCtx.badgesData[key].id
    );

    if (currBadge) {
      badgeJSXElements.push(
        <Badge
          key={key}
          id={currBadge.id}
          img={badgeDataCtx.badgesData[key].img}
          classes={classes.earnedBadge}
          onBadgeClicked={props.onBadgeClicked}
        />
      );
    } else {
      if (badgeDataCtx.viewingMyBadges) {
        //do nothing
      } else {
        badgeJSXElements.push(
          <Badge
            key={key}
            id={badgeDataCtx.badgesData[key].id}
            img={badgeDataCtx.badgesData[key].img}
            classes={classes.unearnedBadge}
            onBadgeClicked={props.onBadgeClicked}
          />
        );
      }
    }
  }

  return <div>{badgeJSXElements}</div>;
};

export default Badges;
