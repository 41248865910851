import TabButton from "../UI/TabButton";
import classes from "./TabBanner.module.css";

const TabBanner = (props) => {
  return (
    <span className={classes["tab-banner"]}>
      {/* <TabButton label="Grade 4-5" id="G45"/>
      <TabButton label="Grade 6-7" id="G67"/>
      <TabButton label="Grade 8-9" id="G89"/> */}
      <TabButton label="Digital Pioneers" id="G45"/>
      <TabButton label="Digital Explorers" id="G67"/>
      <TabButton label="Digital Adventurers" id="G89"/>
      <TabButton label="Teens2Careers" id="T2C"/>
      <TabButton label="My Badges" id="MyBadges"/>
      <TabButton label="My Transcripts" id="MyTranscripts"/>
    </span>
  );
};

export default TabBanner;
