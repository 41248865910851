import React from "react";
import {
  degrees,
  grayscale,
  pdfDocEncodingDecode,
  PDFDocument,
  rgb,
  StandardFonts,
} from "pdf-lib";

// const getTextAttr = async (style) => {
//   if (style === "title") {
//     return {
//       font: await pdfDoc.embedFont(StandardFonts.HelveticaOblique),
//       size: 20,
//       color: rgb(0, 0, 0),
//     };
//   } else if (style === "norm") {
//     return {
//       font: await pdfDoc.embedFont(StandardFonts.TimesRoman),
//       size: 25,
//       color: rgb(0, 0, 0),
//     };
//   } else if (style === "title") {
//     return {
//       font: await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique),
//       size: 40,
//       color: rgb(0, 0, 1),
//     };
//   } else if (style === "heading1") {
//     return {
//       font: await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique),
//       size: 30,
//       color: rgb(0, 0, 1),
//     };
//   }
// };

export const draw90DegCenteredText = (text, page, textAttr, space) => {
  const { width, height } = page.getSize();
  const textWidth = textAttr.font.widthOfTextAtSize(text, textAttr.size);
  space += textAttr.size * 0.25;
  page.drawText(text, {
    x: width - space,
    y: height / 2 + textWidth / 2,
    size: textAttr.size,
    font: textAttr.font,
    color: textAttr.color,
    rotate: degrees(-90),
  });
  return space + textAttr.size;
};

export const draw90DegMultilineCenteredText = (
  text,
  page,
  textAttr,
  space,
  margin
) => {
  text = text.replace("\n", "");
  const { width, height } = page.getSize();
  const textWidth = textAttr.font.widthOfTextAtSize(text, textAttr.size);

  let line = "";
  const words = text.split(" ");
  const spacing = textAttr.size * 1.2;

  for (let i = 0; i < words.length; i++) {
    const lineWidth =
      textAttr.font.widthOfTextAtSize(line, textAttr.size) +
      textAttr.font.widthOfTextAtSize(words[i], textAttr.size);
    if (lineWidth > height - margin * 2) {
      page.drawText(line, {
        x: width - space - textAttr.size * 1.5,
        y:
          height / 2 + textAttr.font.widthOfTextAtSize(line, textAttr.size) / 2,
        size: textAttr.size,
        font: textAttr.font,
        color: textAttr.color,
        rotate: degrees(-90),
      });
      space += spacing;
      line = `${words[i]} `;
    } else {
      line += `${words[i]} `;
    }
  }
  page.drawText(line, {
    x: width - space - textAttr.size * 1.5,
    y: height / 2 + textAttr.font.widthOfTextAtSize(line, textAttr.size) / 2,
    size: textAttr.size,
    font: textAttr.font,
    color: textAttr.color,
    rotate: degrees(-90),
  });
  space += spacing;
  return space + textAttr.size * 2;
};

export const drawCenteredText = (text, page, textAttr, space) => {
  const { width, height } = page.getSize();
  const textWidth = textAttr.font.widthOfTextAtSize(text, textAttr.size);
  page.drawText(text, {
    x: width / 2 - textWidth / 2,
    y: height - space - textAttr.size * 1.5,
    size: textAttr.size,
    font: textAttr.font,
    color: textAttr.color,
  });
  return space + textAttr.size * 1.2;
};

export const drawMultilineCenteredText = (
  text,
  page,
  textAttr,
  space,
  margin
) => {
  text = text.replace("\n", "");
  const { width, height } = page.getSize();
  const textWidth = textAttr.font.widthOfTextAtSize(text, textAttr.size);

  let line = "";
  const words = text.split(" ");
  const spacing = textAttr.size * 1.2;

  for (let i = 0; i < words.length; i++) {
    const lineWidth =
      textAttr.font.widthOfTextAtSize(line, textAttr.size) +
      textAttr.font.widthOfTextAtSize(words[i], textAttr.size);
    if (lineWidth > width - margin * 2) {
      page.drawText(line, {
        x: width / 2 - textAttr.font.widthOfTextAtSize(line, textAttr.size) / 2,
        y: height - space - textAttr.size * 1.5,
        size: textAttr.size,
        font: textAttr.font,
        color: textAttr.color,
      });
      space += spacing;
      line = `${words[i]} `;
    } else {
      line += `${words[i]} `;
    }
  }

  return space + textAttr.size * 1.2;
};

export const drawMultilineText = (
  text,
  page,
  textAttr,
  space,
  margin,
  indent
) => {
  text = text.replace("\n", "");
  const { width, height } = page.getSize();
  const textWidth = textAttr.font.widthOfTextAtSize(text, textAttr.size);

  let line = "";
  const words = text.split(" ");
  const spacing = textAttr.size * 1.2;

  for (let i = 0; i < words.length; i++) {
    const lineWidth =
      textAttr.font.widthOfTextAtSize(line, textAttr.size) +
      textAttr.font.widthOfTextAtSize(words[i], textAttr.size);
    if (lineWidth > width - margin * 2 - indent) {
      page.drawText(line, {
        x: 20 + indent,
        y: height - space - textAttr.size * 1.5,
        size: textAttr.size,
        font: textAttr.font,
        color: textAttr.color,
      });
      space += spacing;
      line = `${words[i]} `;
    } else {
      line += `${words[i]} `;
    }
  }
  page.drawText(line, {
    x: 20 + indent,
    y: height - space - textAttr.size * 1.5,
    size: textAttr.size,
    font: textAttr.font,
    color: textAttr.color,
  });

  return space + spacing + 30;
};
const PdfTextWriter = () => {};

export default PdfTextWriter;
