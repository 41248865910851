import { useContext, useEffect, useRef, useState } from "react";
import classes from "./CertGenerator.module.css";
import Modal from "../UI/Modal";
import {
  draw90DegCenteredText,
  draw90DegMultilineCenteredText,
} from "./PdfTextWriter";
import {
  degrees,
  drawRectangle,
  grayscale,
  PDFDocument,
  rgb,
  StandardFonts,
} from "pdf-lib";
import BadgeDataContext from "../../store/badgeData-context";
import bg from "../../assets/branding/digital_passport-splashscreen-logo.png";

const CertGenerator = (props) => {
  const badgeDataCtx = useContext(BadgeDataContext);
  const [pdfInfo, setPdfInfo] = useState([]);

  useEffect(() => {
    createPdf();
  }, []);

  const awardInfo = badgeDataCtx.currStudent.earnedBadges.find(
    (e) => e.id === badgeDataCtx.currBadge.id
  );

  const courseInfo = badgeDataCtx.badgesData.find(
    (e) => e.id === badgeDataCtx.currBadge.id
  );

  let nextLine = 50;

  const createPdf = async () => {
    const certText = {
      header: "Mercer County Digital Passport",
      certTitle: "CERTIFICATE OF COMPLETION",
      introText: "This certifies that",
      recipient: awardInfo.recip ? awardInfo.recip : "recip",
      certDesc: `on this day ${
        awardInfo.compDate ? awardInfo.compDate : "compDate"
      } has successfully completed all necessary course work and tasks to earn the following badge that signifies new knowledge and skill in this topic:`,
      courseTitle: courseInfo.courseTitle,
      certifier: awardInfo.awarded_by ? awardInfo.awarded_by : "awarded_by",
      certifierDesc: "Issue By",
      courseTransTitle: `${courseInfo.courseTitle} Course Transcript`,
      courseTrans: `${courseInfo.courseDesc}`,
    };

    const pdfDoc = await PDFDocument.create();
    const badgeImage = await pdfDoc.embedPng(courseInfo.img);
    const bgImage = await pdfDoc.embedPng(bg);

    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    //const page2 = pdfDoc.addPage();

    const smScript = {
      font: await pdfDoc.embedFont(StandardFonts.HelveticaOblique),
      size: 20,
      color: rgb(0, 0, 0),
    };
    const norm = {
      font: await pdfDoc.embedFont(StandardFonts.Helvetica),
      size: 25,
      color: rgb(0, 0, 0),
    };
    const title = {
      font: await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique),
      size: 35,
      color: rgb(0, 0, 1),
    };
    const heading1 = {
      font: await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique),
      size: 30,
      color: rgb(0, 0, 1),
    };

    page.drawRectangle({
      x: 0,
      y: 0,
      width: width,
      height: height,
      color: grayscale(0.95),
    });

    page.drawImage(bgImage, {
      x: 0,
      y: height,
      width: height,
      height: width,
      rotate: degrees(-90),
    });

    nextLine = draw90DegCenteredText(certText.header, page, heading1, nextLine);
    nextLine = draw90DegCenteredText(certText.certTitle, page, title, nextLine);

    nextLine += 130;
    page.drawImage(badgeImage, {
      x: width - nextLine,
      y: height / 2 + 75,
      width: 150,
      height: 150,
      rotate: degrees(-90),
    });
    nextLine += 20;

    nextLine = draw90DegCenteredText(certText.introText, page, norm, nextLine);
    nextLine = draw90DegCenteredText(
      certText.recipient,
      page,
      heading1,
      nextLine
    );
    nextLine = draw90DegMultilineCenteredText(
      certText.certDesc,
      page,
      norm,
      nextLine,
      80
    );
    nextLine = draw90DegCenteredText(
      certText.courseTitle,
      page,
      heading1,
      nextLine
    );
    page.drawRectangle({
      x: 85,
      y: height / 2 - 150,
      width: 2,
      height: 300,
    });
    nextLine += 10;
    nextLine = draw90DegCenteredText(
      certText.certifierDesc,
      page,
      smScript,
      nextLine
    );
    nextLine = draw90DegCenteredText(
      "Mercer County Digital Passport",
      page,
      smScript,
      nextLine
    );

    const pdfBytes = await pdfDoc.save();
    const docUrl = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );

    setPdfInfo(docUrl);
  };

  return (
    <Modal onClose={props.onClose}>
      <iframe
        className={classes.frame}
        title="certPdf"
        width="600"
        height="900"
        src={pdfInfo}
      />
    </Modal>
  );
};

export default CertGenerator;
